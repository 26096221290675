
import { Component, Prop, Vue } from 'vue-property-decorator';

import ColorField from '@/components/shared/forms/ColorField.vue';
import { BrandingColors } from '@govflanders/mbp-admin-panel-shared';

@Component({
  components: {
    ColorField,
  },
})
export default class BrandingColorsBlock extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  public value: BrandingColors;

  public get currentValue(): BrandingColors {
    return this.value;
  }

  public set currentValue(value: BrandingColors) {
    this.$emit('input', value);
  }
}
